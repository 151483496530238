<template>
  <!-- eslint-disable -->
  <v-container id="rfp-page" fluid tag="section">
    <v-row justify="center">
      <v-col cols="12">
        <base-material-card color="primary" class="px-5 py-3 cityDetailsContainer">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">City Subscriber View</div>
            <div class="
                d-flex
                flex-row
                align-center
                justify-start
                subtitle-1
                font-weight-light
              ">
              <span>This shows the City Subscriber profile</span>
            </div>
          </template>
          <div>
            <v-overlay :value="overlay" opacity="1" color="white" z-index="0" absolute="absolute">
              <v-progress-circular indeterminate size="100" color="primary">
              </v-progress-circular>
            </v-overlay>
          </div>
          <v-card-text>
            <v-row class="d-flex flex-row align-start justify-space-between" v-if="details">
              <!-- city details details -->
              <v-col cols="12" class="px-0">
                <v-row class="px-0">
                  <v-col cols="12">
                    <h3 class="headline text-left">City Details</h3>
                  </v-col>
                </v-row>
                <!-- individual row for city subscriber details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left">City Name:</span>
                  </v-col>
                  <v-col cols="8">
                    <span class="subtitle-1 text-left" v-if="details.user.cityName">{{ details.user.cityName }}</span>
                    <span class="subtitle-1 text-left platinum--text text--darken-3" v-else>n/a</span>
                  </v-col>
                </v-row>
                <!-- end of individual row for city subscriber details -->
                <!-- individual row for city subscriber details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left">Country:</span>
                  </v-col>
                  <v-col cols="8">
                    <span class="subtitle-1 text-left" v-if="details.user.country">{{ details.user.country }}</span>
                    <span class="subtitle-1 text-left platinum--text text--darken-3" v-else>n/a</span>
                  </v-col>
                </v-row>
                <!-- end of individual row for city details -->
                <!-- individual row for city details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left">City's Official Website:</span>
                  </v-col>
                  <v-col cols="8">
                    <v-tooltip bottom v-if="details.profile && details.profile.website">
                      <template v-slot:activator="{ on }">
                        <span class="subtitle-1 text-left" v-on="on">
                          <a :href="validateUrl(details.profile.website)" target="_blank">{{
                            validateUrl(details.profile.website) }}</a>
                        </span>
                      </template>
                      <span>
                        Visit
                        <b>
                          <i>{{ validateUrl(details.profile.website) }}</i>
                        </b>
                      </span>
                    </v-tooltip>
                    <span class="subtitle-1 text-left platinum--text text--darken-3" v-else>n/a</span>
                  </v-col>
                </v-row>
                <!-- end of individual row for city details -->
                <!-- individual row for city details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left">Type of Representation:</span>
                  </v-col>
                  <v-col cols="8">
                    <span class="subtitle-1 text-left" v-if="details.user.type">{{ details.user.type }}</span>
                    <span class="subtitle-1 text-left platinum--text text--darken-3" v-else>n/a</span>
                  </v-col>
                </v-row>
                <!-- end of individual row for city details -->
                <!-- individual row for city details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left">Proof of Representation:</span>
                  </v-col>
                  <v-col cols="8">
                    <v-chip class="proof-link ma-0 px-4" color="secondary" label text-color="white"
                      :href="details.profile.proof[0]" target="_blank" v-if="details.profile && details.profile.proof">
                      <span class="subtitle-1">Download</span>
                      <v-icon right>mdi-file-download</v-icon>
                    </v-chip>
                    <span class="subtitle-1 text-left platinum--text text--darken-3" v-else>n/a</span>
                  </v-col>
                </v-row>
                <!-- end of individual row for city details -->
                <!-- individual row for city details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left">City Office Address:</span>
                  </v-col>
                  <v-col cols="8">
                    <span v-if="details.profile && details.profile.address" class="subtitle-1 text-left">{{
                      `${details.profile.address.street
                        ? details.profile.address.street
                        : ""
                      } ${details.profile.address.postalCode
                        ? `[${details.profile.address.postalCode}]`
                        : ""
                      } ${details.profile.address.city
                        ? `, ${details.profile.address.city}, `
                        : ""
                      }${details.profile.address.country
                        ? `${details.profile.address.country}`
                        : ""
                      }`
                    }}</span>
                    <span class="subtitle-1 text-left platinum--text text--darken-3" v-else>n/a</span>
                  </v-col>
                </v-row>
                <!-- end of individual row for city details -->
                <!-- individual row for venue details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left">Social Media Channels:</span>
                  </v-col>
                  <v-col cols="8">
                    <v-row class="d-flex flex-row flex-wrap mx-0" v-if="
                      details.profile &&
                      details.profile.socialMedia.length > 0
                    ">
                      <v-col v-for="socialMedia in details.profile.socialMedia" :key="socialMedia._id"
                        v-show="socialMedia.link" class="py-0 pl-0 flex-grow-0">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-chip v-on="on" class="mr-2 mb-2" label :href="validateUrl(socialMedia.link)"
                              target="_blank" :color="getSocialColor(socialMedia.name)">
                              <font-awesome-icon :icon="['fab', socialMedia.icon]" class="mr-2" size="2x"
                                color="white" />
                              <span class="
                                  text-left
                                  caption
                                  text-capitalize
                                  white--text
                                ">{{ socialMedia.name }}</span>
                            </v-chip>
                          </template>
                          <span>{{ validateUrl(socialMedia.link) }}</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                    <v-row class="d-flex flex-row mx-0" v-else>
                      <span class="
                          subtitle-1
                          text-left
                          platinum--text
                          text--darken-3
                        ">n/a</span>
                    </v-row>
                  </v-col>
                </v-row>
                <!-- end of individual row for city details -->
                <!-- individual row for city details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left">Main topic city specializes in:</span>
                  </v-col>
                  <v-col cols="8" v-if="details && details.topics && details.topics.topic[0]">
                    <v-row class="d-flex flex-row flex-wrap mx-0" v-if="details.topics.topic">
                      <v-chip class="mr-2 mb-2 grey--text" label v-for="(topic) in details.topics.topic">{{
                        topic
                      }}</v-chip>
                    </v-row>
                    <v-row class="mx-0" v-else>
                      <span class="
                          subtitle-1
                          text-left
                          platinum--text
                          text--darken-3
                        ">n/a</span>
                    </v-row>
                  </v-col>
                  <v-col cols="8" v-else>
                    <v-row class="mx-0">
                      <span class="
                          subtitle-1
                          text-left
                          platinum--text
                          text--darken-3
                        ">n/a</span>
                    </v-row>
                  </v-col>
                </v-row>
                <!-- end of individual row for city details -->
                <!-- individual row for city details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left">Subtopics city specializes in:</span>
                  </v-col>
                  <v-col cols="8" v-if="
                    details &&
                    details.topics &&
                    details.topics.supTopic
                  ">
                    <v-row class="d-flex flex-row flex-wrap mx-0" v-if="details.topics.supTopic.length > 1">
                      <v-chip class="mr-2 mb-2 grey--text" label :key="index" v-for="(supTopic, index) in details.topics
                        .supTopic">{{ supTopic }}</v-chip>
                    </v-row>
                    <v-row class="mx-0" v-else>
                      <span class="
                          subtitle-1
                          text-left
                          platinum--text
                          text--darken-3
                        ">n/a</span>
                    </v-row>
                  </v-col>
                  <v-col cols="8" v-else>
                    <v-row class="mx-0">
                      <span class="
                          subtitle-1
                          text-left
                          platinum--text
                          text--darken-3
                        ">n/a</span>
                    </v-row>
                  </v-col>
                </v-row>
                <!-- end of individual row for city details -->
              </v-col>
              <!-- city rep. details -->
              <v-col cols="12" class="px-0">
                <v-row class="px-0">
                  <v-col cols="12">
                    <h3 class="headline text-left">
                      City (Representative) Details
                    </h3>
                  </v-col>
                </v-row>
                <!-- individual row for city rep. details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left">Name:</span>
                  </v-col>
                  <v-col cols="8">
                    <span class="subtitle-1 text-left" v-if="details.user.name">{{ details.user.name }}</span>
                    <span class="subtitle-1 text-left platinum--text text--darken-3" v-else>n/a</span>
                  </v-col>
                </v-row>
                <!-- end of individual row for city rep. details -->
                <!-- individual row for city rep. details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left">Email:</span>
                  </v-col>
                  <v-col cols="8">
                    <v-tooltip bottom v-if="details.user.email">
                      <template v-slot:activator="{ on }">
                        <span class="subtitle-1 text-left" v-on="on">
                          <a :href="`mailto:${details.user.email}`">{{
                            details.user.email
                          }}</a>
                        </span>
                      </template>
                      <span>
                        Send an email to
                        <b>{{ details.user.name }}</b> via
                        <b>
                          <i>{{ details.user.email }}</i>
                        </b>
                      </span>
                    </v-tooltip>
                    <span class="subtitle-1 text-left platinum--text text--darken-3" v-else>n/a</span>
                  </v-col>
                </v-row>
                <!-- end of individual row for city rep. details -->
                <!-- individual row for city rep. details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left">Phone:</span>
                  </v-col>
                  <v-col cols="8">
                    <v-tooltip bottom v-if="
                      details.profile &&
                      details.profile.telephoneNo &&
                      details.profile.telephoneNo.no
                    ">
                      <template v-slot:activator="{ on }">
                        <span class="subtitle-1 text-left" v-on="on">
                          <a :href="`tel:+${details.profile.telephoneNo.code +
                            details.profile.telephoneNo.no
                            }`">{{
                              `+${details.profile.telephoneNo.code} ${details.profile.telephoneNo.no}`
                            }}</a>
                        </span>
                      </template>
                      <span>
                        Call
                        <b>{{ details.user.name }}</b> via
                        <b>
                          <i>{{
                            `+${details.profile.telephoneNo.code} ${details.profile.telephoneNo.no}`
                          }}</i>
                        </b>
                      </span>
                    </v-tooltip>
                    <span class="subtitle-1 text-left platinum--text text--darken-3" v-else>n/a</span>
                  </v-col>
                </v-row>
                <!-- end of individual row for city rep. details -->
                <!-- individual row for city rep. details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left">Joined Date:</span>
                  </v-col>
                  <v-col cols="8">
                    <span class="subtitle-1 text-left" v-if="computedDateFormat">{{ computedDateFormat }}</span>
                    <span class="subtitle-1 text-left platinum--text text--darken-3" v-else>n/a</span>
                  </v-col>
                </v-row>
                <!-- end of individual row for city rep. details -->
              </v-col>
              <!-- city payment details -->
              <v-col cols="12" class="px-0">
                <v-row class="px-0">
                  <v-col cols="12">
                    <h3 class="headline text-left">City Payment Details</h3>
                  </v-col>
                </v-row>
                <!-- individual row for city details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left">City Profile:</span>
                  </v-col>
                  <v-col cols="8">
                    <span class="subtitle-1 text-left primary--text" v-if="details.user.isAdvance">Advance
                      Profile</span>
                    <span class="subtitle-1 text-left secondary--text" v-else>Basic Profile</span>
                  </v-col>
                </v-row>
                <!-- end of individual row for city details -->
                <!-- individual row for city details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left">City Approval State:</span>
                  </v-col>
                  <v-col cols="8">
                    <span class="subtitle-1 text-left success--text" v-if="details.user.isApproved">Approved</span>
                    <span class="subtitle-1 text-left warning--text" v-else>Pending/Rejected</span>
                  </v-col>
                </v-row>
                <!-- end of individual row for city details -->
              </v-col>
              <v-col cols="12" class="px-0">
                <v-row class="px-0">
                  <v-col cols="5">
                    <h3 class="headline text-left" style="margin-bottom: 20px;">Sustainability Certificates</h3>
                    <v-divider style="margin-bottom: 20px;"></v-divider>
                  </v-col>
                </v-row>
                <v-row class="px-0" v-for="(url, index) in sustainabilityDocs" :key="url" style="margin-bottom: -40px;">
                  <v-col cols="4">{{ index + 1 }} :- <a
                      :href="`https://conferli-storage.s3.amazonaws.com/sustainabilityCertificates/` + url"
                      target="_blank">{{ url
                      }}</a></v-col>
                  <v-col cols="2">
                    <v-btn icon color="red lighten-1" @click="removeCertificate(index)">
                      <font-awesome-icon :icon="['fas', 'trash']" />
                    </v-btn>
                  </v-col>
                </v-row>
                <h6 class="text-left" v-if="sustainabilityDocs.length < 1" style="margin-top: 25px;">Not uploaded yet!
                </h6>
              </v-col>

              <!-- Requested Features -->
              <v-col cols="12" class="px-0">
                <v-row class="px-0 py-5">
                  <v-col cols="5">
                    <h3 class="headline text-left" style="margin-bottom: 20px;">Requested Features</h3>
                  </v-col>
                </v-row>

                <h3 class="title text-left" style="margin-bottom: 20px;">Bureau Services</h3>

                <v-row class="px-0 align-center">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left">Quarterly Research:</span>
                  </v-col>
                  <v-col cols="2">
                    <v-switch class="text-center" v-model="quarterlyResearch"></v-switch>
                  </v-col>
                  <v-col cols="4">
                    <v-col cols="10" class="py-0">
                      <v-menu v-model="quarterlyResearchmenu" :close-on-content-click="false" :nudge-right="40"
                        transition="scale-transition" offset-y min-width="auto">
                        <template #activator="{ on, attrs }">
                          <v-text-field v-bind="attrs" v-on="on" style="margin-bottom: -30px;"
                            label="Select Expiry Date" outlined v-model="quarterlyResearchdate"></v-text-field>
                        </template>
                        <v-date-picker v-model="quarterlyResearchdate" no-title scrollable
                          :min="new Date().toISOString().substr(0, 10)"
                          @input="quarterlyResearchmenu = false"></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-col>
                </v-row>
                <v-row class="px-0 align-center">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left">Event Booking:</span>
                  </v-col>
                  <v-col cols="2">
                    <v-switch class="text-center" v-model="eventBooking"></v-switch>
                  </v-col>
                  <v-col cols="4">
                    <v-col cols="10" class="py-0">
                      <v-menu v-model="eventBookingmenu" :close-on-content-click="false" :nudge-right="40"
                        transition="scale-transition" offset-y min-width="auto">
                        <template #activator="{ on, attrs }">
                          <v-text-field v-bind="attrs" v-on="on" style="margin-bottom: -30px" label="Select Expiry Date"
                            outlined v-model="eventBookingdate"></v-text-field>
                        </template>
                        <v-date-picker v-model="eventBookingdate" no-title scrollable
                          :min="new Date().toISOString().substr(0, 10)"
                          @input="eventBookingmenu = false"></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-col>
                </v-row>

                <h3 class="title text-left py-6" style="margin-bottom: 20px;">Profile</h3>

                <v-row class="px-0 align-center">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left">Premium Listing Services:</span>
                  </v-col>
                  <v-col cols="2">
                    <v-switch class="text-center" v-model="premiumListing"></v-switch>
                  </v-col>
                  <v-col cols="4">
                    <v-col cols="10" class="py-0">
                      <v-menu v-model="premiumListingmenu" :close-on-content-click="false" :nudge-right="40"
                        transition="scale-transition" offset-y min-width="auto">
                        <template #activator="{ on, attrs }">
                          <v-text-field v-bind="attrs" v-on="on" style="margin-bottom: -30px;"
                            label="Select Expiry Date" outlined v-model="premiumListingdate"></v-text-field>
                        </template>
                        <v-date-picker v-model="premiumListingdate" no-title scrollable
                          :min="new Date().toISOString().substr(0, 10)"
                          @input="premiumListingmenu = false"></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-col>
                </v-row>
                <v-row class="px-0 align-center">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left">Direct Contact:</span>
                  </v-col>
                  <v-col cols="2">
                    <v-switch class="text-center" v-model="directContact"></v-switch>
                  </v-col>
                  <v-col cols="4">
                    <v-col cols="10" class="py-0">
                      <v-menu v-model="directContactmenu" :close-on-content-click="false" :nudge-right="40"
                        transition="scale-transition" offset-y min-width="auto">
                        <template #activator="{ on, attrs }">
                          <v-text-field v-bind="attrs" v-on="on" style="margin-bottom: -30px;"
                            label="Select Expiry Date" outlined v-model="directContactdate"></v-text-field>
                        </template>
                        <v-date-picker v-model="directContactdate" no-title scrollable
                          :min="new Date().toISOString().substr(0, 10)"
                          @input="directContactmenu = false"></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-col>
                </v-row>
                <v-row class="px-0 align-center">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left">Open Tenders:</span>
                  </v-col>
                  <v-col cols="2">
                    <v-switch class="text-center" v-model="openTenders"></v-switch>
                  </v-col>
                  <v-col cols="4">
                    <v-col cols="10" class="py-0">
                      <v-menu v-model="openTendersmenu" :close-on-content-click="false" :nudge-right="40"
                        transition="scale-transition" offset-y min-width="auto">
                        <template #activator="{ on, attrs }">
                          <v-text-field v-bind="attrs" v-on="on" style="margin-bottom: -30px;"
                            label="Select Expiry Date" outlined v-model="openTendersdate"></v-text-field>
                        </template>
                        <v-date-picker v-model="openTendersdate" no-title scrollable
                          :min="new Date().toISOString().substr(0, 10)"
                          @input="openTendersmenu = false"></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-col>
                </v-row>
                <v-row class="px-0 align-center">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left">Success Story/Case Studies:</span>
                  </v-col>
                  <v-col cols="2">
                    <v-switch class="text-center" v-model="caseStudies"></v-switch>
                  </v-col>
                  <v-col cols="4">
                    <v-col cols="10" class="py-0">
                      <v-menu v-model="caseStudiesmenu" :close-on-content-click="false" :nudge-right="40"
                        transition="scale-transition" offset-y min-width="auto">
                        <template #activator="{ on, attrs }">
                          <v-text-field v-bind="attrs" v-on="on" style="margin-bottom: -30px;"
                            label="Select Expiry Date" outlined v-model="caseStudiesdate"></v-text-field>
                        </template>
                        <v-date-picker v-model="caseStudiesdate" no-title scrollable
                          :min="new Date().toISOString().substr(0, 10)"
                          @input="caseStudiesmenu = false"></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-col>
                </v-row>
                <v-row class="px-0 align-center">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left">Dedicated City Pitch:</span>
                  </v-col>
                  <v-col cols="2">
                    <v-switch class="text-center" v-model="cityPitch"></v-switch>
                  </v-col>
                  <v-col cols="4">
                    <v-col cols="10" class="py-0">
                      <v-menu v-model="cityPitchmenu" :close-on-content-click="false" :nudge-right="40"
                        transition="scale-transition" offset-y min-width="auto">
                        <template #activator="{ on, attrs }">
                          <v-text-field v-bind="attrs" v-on="on" style="margin-bottom: -30px;"
                            label="Select Expiry Date" outlined v-model="cityPitchdate"></v-text-field>
                        </template>
                        <v-date-picker v-model="cityPitchdate" no-title scrollable
                          :min="new Date().toISOString().substr(0, 10)" @input="cityPitchmenu = false"></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-col>
                </v-row>
                <v-row class="px-0 align-center">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left">Destination Videos:</span>
                  </v-col>
                  <v-col cols="2">
                    <v-switch class="text-center" v-model="destinationVideos"></v-switch>
                  </v-col>
                  <v-col cols="4">
                    <v-col cols="10" class="py-0">
                      <v-menu v-model="destinationVideosmenu" :close-on-content-click="false" :nudge-right="40"
                        transition="scale-transition" offset-y min-width="auto">
                        <template #activator="{ on, attrs }">
                          <v-text-field v-bind="attrs" v-on="on" style="margin-bottom: -30px;"
                            label="Select Expiry Date" outlined v-model="destinationVideosdate"></v-text-field>
                        </template>
                        <v-date-picker v-model="destinationVideosdate" no-title scrollable
                          :min="new Date().toISOString().substr(0, 10)"
                          @input="destinationVideosmenu = false"></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-col>
                </v-row>

              </v-col>

              <v-col cols="12" class="px-0" style="margin-top: 20px">
                <v-row class="px-0">
                  <v-col cols="5">
                    <h3 class="headline text-left">
                      Update Representative Account
                    </h3>
                    <v-divider></v-divider>
                    <v-row class="px-0">
                      <v-col cols="10" style="margin-top: 20px">
                        <v-text-field style="margin-bottom: -40px" type="text" label="City Subscriber Name" outlined
                          v-model="name"></v-text-field>
                      </v-col>
                      <v-col cols="10">
                        <v-text-field style="margin-bottom: -40px" type="text" label="Email" outlined
                          v-model="email"></v-text-field>
                      </v-col>
                      <v-col cols="10">
                        <v-text-field style="margin-bottom: -40px" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="show ? 'text' : 'password'" v-model="password" label="New Password" outlined
                          @click:append="show = !show" :error-messages="errorPassword"></v-text-field>
                      </v-col>
                      <v-col cols="10">
                        <v-btn block style="background-color: #35a7ff;" @click="dialog = true">
                          <v-icon left dark>
                            mdi-card-account-details
                          </v-icon>
                          Update Representative Account
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" max-width="600px">
      <v-card height="45vh" style="overflow-y: scroll;padding-right: 10px;padding-left: 10px;padding-top: 20px">
        <v-card-title class="px-2 px-sm-6">
          <v-row class="pb-3">
            <v-col cols="8" lg="10" class="d-flex flex-column justify-center align-start py-0">
              <h2 class="headline text-left">Please confirm</h2>
            </v-col>
            <v-col cols="4" lg="2" class="d-flex flex-column justify-center align-end py-0">
              <v-btn class="dialog-close-btn" icon color="grey lighten-1" @click="dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row v-if="details.user.email !== email || details.user.name !== name || password !== ''">
            <v-col cols="6" md="6">
              <span class="text-left caption text-capitalize red--text">Current</span>
              <br />
              <span class="text-left caption text-capitalize black--text" v-if="details.user.name !== name">Name :- {{
                details.user.name }}</span>
              <v-divider></v-divider>
              <span class="text-left caption text-capitalize black--text" v-if="details.user.email !== email">Email :-
                {{
                  details.user.email.toLowerCase()
                }}</span>
              <v-divider></v-divider>
              <span class="text-left caption text-capitalize black--text" v-if="password !== ''">Password :-
                hidden</span>
            </v-col>
            <v-col cols="6" md="6">
              <span class="text-left caption text-capitalize green--text">New</span>
              <br />
              <span class="text-left caption text-capitalize black--text" v-if="details.user.name !== name">Name :- {{
                name
              }}</span>
              <v-divider></v-divider>
              <span class="text-left caption text-capitalize black--text" v-if="details.user.email !== email">Email :-
                {{
                  email
                }}</span>
              <v-divider></v-divider>
              <span class="text-left caption text-capitalize black--text" v-if="password !== ''">Password :- {{
                password === '' ? 'Not Changed!' : password
              }}</span>
            </v-col>
          </v-row>
          <v-row v-else style="text-align: center">
            <span style="text-align: center;width: 100%;margin-top: 10vh"
              class="text-center caption text-capitalize grey--text font-weight-bold">There is no changes to
              update!</span>
          </v-row>
        </v-card-text>
        <v-card-actions class="px-6"
          v-if="details.user.email !== email || details.user.name !== name || password !== ''">
          <v-row class="d-flex flex-column-reverse flex-sm-row align-center justify-space-between mx-0">
            <v-col cols="12" sm="3" class="d-flex flex-column align-center align-sm-start px-0">
              <v-btn class="px-8" color="secondary" @click="dialog = false" tile outlined depressed bottom>
                Cancel
              </v-btn>
            </v-col>
            <v-col cols="12" sm="3" class="d-flex flex-column align-center align-sm-end px-0"
              v-if="details.user.email !== email || details.user.name !== name || password !== ''">
              <v-btn class="px-8 mx-0" color="secondary" tile depressed bottom right @click="updateProfile">
                Confirm
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Apicalls from "../../../services/login.service";
import { mapGetters } from "vuex";
import CitySubApi from "../../../services/citySubscribers.service";
export default {
  name: "CitySubView",
  data() {
    return {
      dialog: false,
      details: null,
      overlay: true,
      errorPassword: null,
      password: null,
      email: null,
      name: null,
      show: false,
      quarterlyResearch: false,
      eventBooking: false,
      premiumListing: false,
      directContact: false,
      openTenders: false,
      caseStudies: false,
      cityPitch: false,
      destinationVideos: false,
      quarterlyResearchmenu: false,
      eventBookingmenu: false,
      premiumListingmenu: false,
      directContactmenu: false,
      openTendersmenu: false,
      caseStudiesmenu: false,
      cityPitchmenu: false,
      destinationVideosmenu: false,
      quarterlyResearchdate: '',
      eventBookingdate: '',
      premiumListingdate: '',
      directContactdate: '',
      openTendersdate: '',
      caseStudiesdate: '',
      cityPitchdate: '',
      destinationVideosdate: '',
      staticErrorMessages: {
        email: {
          isEmpty: "Email Address cannot be empty"
        },
        password: {
          isEmpty: "Password cannot be empty"
        }
      },
      sustainabilityDocs: [],
      sustainabilityDocLinks: [],
    };
  },
  computed: {
    ...mapGetters({
      email: "getUserEmail",
    }),
    computedDateFormat() {
      return this.details.user.created_at.split("T")[0];
    },
  },
  mounted() {
    this.initFn();
  },
  watch: {
    premiumListing(val) {
      this.updateFeatureEnableDisable("premiumListingServices", "premiumListingServicesExp", val, this.premiumListingdate);
    },
    directContact(val) {
      this.updateFeatureEnableDisable("enableDirectContact", "enableDirectContactExp", val, this.directContactdate);
    },
    quarterlyResearch(val) {
      this.updateFeatureEnableDisable("quarterlyResearch", "quarterlyResearchExp", val, this.quarterlyResearchdate);
    },
    openTenders(val) {
      this.updateFeatureEnableDisable("enableOpenTender", "enableOpenTenderExp", val, this.openTendersdate);
    },
    destinationVideos(val) {
      this.updateFeatureEnableDisable("destinationVideos", "destinationVideosExp", val, this.destinationVideosdate);
    },
    caseStudies(val) {
      this.updateFeatureEnableDisable("caseStudies", "caseStudiesExp", val, this.caseStudiesdate);
    },
    eventBooking(val) {
      this.updateFeatureEnableDisable("eventBooking", "eventBookingExp", val, this.eventBookingdate);
    },
    cityPitch(val) {
      this.updateFeatureEnableDisable("dedicatedCityPitch", "dedicatedCityPitchExp", val, this.cityPitchdate);
    },
    premiumListingdate(val) {
      this.updateFeatureEnableDisable("premiumListingServices", "premiumListingServicesExp", true, val);
    },
    directContactdate(val) {
      this.updateFeatureEnableDisable("enableDirectContact", "enableDirectContactExp", true, val);
    },
    quarterlyResearchdate(val) {
      this.updateFeatureEnableDisable("quarterlyResearch", "quarterlyResearchExp", true, val);
    },
    openTendersdate(val) {
      this.updateFeatureEnableDisable("enableOpenTender", "enableOpenTenderExp", true, val);
    },
    destinationVideosdate(val) {
      this.updateFeatureEnableDisable("destinationVideos", "destinationVideosExp", true, val);
    },
    caseStudiesdate(val) {
      this.updateFeatureEnableDisable("caseStudies", "caseStudiesExp", true, val);
    },
    eventBookingdate(val) {
      this.updateFeatureEnableDisable("eventBooking", "eventBookingExp", true, val);
    },
    cityPitchdate(val) {
      this.updateFeatureEnableDisable("dedicatedCityPitch ", "dedicatedCityPitchExp", true, val);
    },

  },
  methods: {
    initFn() {
      this.overlay = true;
      let payload = {
        // email: this.email
        email: localStorage.getItem("setUserEmail"),
      };
      Apicalls.getDetails(payload)
        .then((res) => {
          // console.log(JSON.stringify(res.data.content));
          this.details = res.data.content;
          this.overlay = false;
          this.name = this.details.user.name;
          this.email = this.details.user.email;
          this.password = '';
          this.sustainabilityCertificates;
          this.details.topics.sustainabilityCertificates.forEach(element => {
            try {
              this.sustainabilityDocLinks.push(element);
              this.sustainabilityDocs.push(decodeURIComponent(element.split("sustainabilityCertificates/")[1].replace(/%20/g, ' ').replace(/%28/g, '(').replace(/%29/g, ')')))
            } catch (e) {
              console.log('sus certificate replace err')
            }
          });
          this.updateFromDB()
        })
        .catch();
      // this.updateFromDB()
    },
    async updateFeatureEnableDisable(feature, featureExp, status, date) {

      this.isLoading = true;
      if (date === '' || date === 'N/A') {
        const today = new Date();
        today.setFullYear(today.getFullYear() + 1); // Add 1 year to the current year
        const formattedDate = today.toISOString().split("T")[0];
        date = formattedDate;
      }
      await CitySubApi.SetFeatureStatus(this.details.topics._id, status, date, feature, featureExp);
      // this.updateFromDB()
    },
    async updateFromDB() {
      this.isLoading = true;
      await CitySubApi.GetFeatureStatus(this.details.topics._id).then(res => {


        this.quarterlyResearch = res.data.features.quarterlyResearch === 'true' || res.data.features.quarterlyResearch === true ? true : false;
        if (!this.quarterlyResearch) {
          this.quarterlyResearchdate = '';
        } else {
          this.quarterlyResearchdate = res.data.features.quarterlyResearchExp;
        }
        this.eventBooking = res.data.features.eventBooking === 'true' || res.data.features.eventBooking === true ? true : false;
        if (!this.eventBooking) {
          this.eventBookingdate = '';
        } else {
          this.eventBookingdate = res.data.features.eventBookingExp;
        }
        this.caseStudies = res.data.features.caseStudies === 'true' || res.data.features.caseStudies === true ? true : false;
        if (!this.caseStudies) {
          this.caseStudiesdate = '';
        } else {
          this.caseStudiesdate = res.data.features.caseStudiesExp;
        }
        this.cityPitch = res.data.features.dedicatedCityPitch === 'true' || res.data.features.dedicatedCityPitch === true ? true : false;
        if (!this.cityPitch) {
          this.cityPitchdate = '';
        } else {
          this.cityPitchdate = res.data.features.dedicatedCityPitchExp;
        }
        this.destinationVideos = res.data.features.destinationVideos === 'true' || res.data.features.destinationVideos === true ? true : false;
        if (!this.destinationVideos) {
          this.destinationVideosdate = '';
        } else {
          this.destinationVideosdate = res.data.features.destinationVideosExp;
        }
        this.openTenders = res.data.features.enableOpenTender === 'true' || res.data.features.enableOpenTender === true ? true : false;
        if (!this.openTenders) {
          this.openTendersdate = '';
        } else {
          this.openTendersdate = res.data.features.enableOpenTenderExp;
        }
        this.premiumListing = res.data.features.premiumListingServices === 'true' || res.data.features.premiumListingServices === true ? true : false;
        if (!this.premiumListing) {
          this.premiumListingdate = '';
        } else {
          this.premiumListingdate = res.data.features.premiumListingServicesExp;
        }
        this.directContact = res.data.features.enableDirectContact === 'true' || res.data.features.enableDirectContact === true ? true : false;
        if (!this.directContact) {
          this.directContactdate = '';
        } else {
          this.directContactdate = res.data.features.enableDirectContactExp;
        }



      });
      // await CitySubApi.GetFeatureStatus("FEATURES.MEMBER_BOOKING").then(res => {
      //     try {
      //         this.eventBooking = res.data.details.status === 'true' || res.data.details.status === true ? true : false;
      //     } catch (e) {
      //         this.eventBooking = false;
      //     }
      // });await CitySubApi.GetFeatureStatus("FEATURES.EVENT_BOOKING").then(res => {
      //     try {
      //         this.caseStudies = res.data.details.status === 'true' || res.data.details.status === true ? true : false;
      //     } catch (e) {
      //         this.caseStudies = false;
      //     }
      // });
      // await CitySubApi.GetFeatureStatus("FEATURES.MEMBER_BOOKING").then(res => {
      //     try {
      //         this.cityPitch = res.data.details.status === 'true' || res.data.details.status === true ? true : false;
      //     } catch (e) {
      //         this.cityPitch = false;
      //     }
      // });await CitySubApi.GetFeatureStatus("FEATURES.EVENT_BOOKING").then(res => {
      //     try {
      //         this.destinationVideos = res.data.details.status === 'true' || res.data.details.status === true ? true : false;
      //     } catch (e) {
      //         this.destinationVideos = false;
      //     }
      // });
      // await CitySubApi.GetFeatureStatus("FEATURES.MEMBER_BOOKING").then(res => {
      //     try {
      //         this.openTenders = res.data.details.status === 'true' || res.data.details.status === true ? true : false;
      //     } catch (e) {
      //         this.openTenders = false;
      //     }
      // });
      // await CitySubApi.GetFeatureStatus("FEATURES.EVENT_BOOKING").then(res => {
      //     try {
      //         this.premiumListing = res.data.details.status === 'true' || res.data.details.status === true ? true : false;
      //     } catch (e) {
      //         this.premiumListing = false;
      //     }
      // });
      // await CitySubApi.GetFeatureStatus("FEATURES.MEMBER_BOOKING").then(res => {
      //     try {
      //         this.directContact = res.data.details.status === 'true' || res.data.details.status === true ? true : false;
      //     } catch (e) {
      //         this.directContact = false;
      //     }
      // });
      this.isLoading = false;
    },
    async removeCertificate(index) {
      const payload = {
        profile: this.details.user._id,
        url: this.sustainabilityDocLinks[index]
      }
      await CitySubApi.deleteSustainabilityCertificate(payload);
      this.sustainabilityDocLinks = []
      this.sustainabilityDocs = []
      await this.initFn();
    },
    getSocialColor(name) {
      if (name == "LinkedIn") {
        return "#0a66c2";
      } else if (name == "Twitter") {
        return "#1da1f2";
      } else if (name == "Youtube") {
        return "#ff0000";
      } else if (name == "Instagram") {
        return "#f00075";
      } else if (name == "Vimeo") {
        return "#00adef";
      } else if (name == "Flickr") {
        return "#ff0084";
      } else {
        return "secondary";
      }
    },
    validateUrl(url) {
      if (!url) return;
      let newUrl = window.decodeURIComponent(url);
      newUrl = newUrl.trim().replace(/\s/g, "");
      if (/^(:\/\/)/.test(newUrl)) {
        return `https${newUrl}`;
      }
      if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
        return `https://${newUrl}`;
      }
      return newUrl;
    },
    updateProfile() {
      var input = document.createElement('input');
      input.type = 'email';
      input.required = true;
      input.value = this.email.toLowerCase();
      if (!/\S+@\S+\.\S+/.test(this.email.toLowerCase())) {
        let payload2 = {
          snackBarText: `Given email address is not valid!`,
          snackBarColor: "error",
        };
        this.$store.dispatch("notify/setSnackbarMessage", payload2);
      } else {
        const payload = {
          oldEmail: this.details.user.email,
          newEmail: this.email,
          newName: this.name,
          password: this.password
        }
        CitySubApi.updateAccount(payload).then((res) => {
          console.log(res)
          Apicalls.getDetails({ email: this.email })
            .then((res) => {
              // console.log(res.data);
              this.details = res.data.content;
              this.isApiload = true;
              this.overlay = false;
              this.name = this.details.user.name;
              this.email = this.details.user.email;
              this.password = '';
            })
            .catch();
          this.dialog = false;
          let payload2 = {
            snackBarText: `Representative Account Updated!`,
            snackBarColor: "success",
          };
          this.$store.dispatch("notify/setSnackbarMessage", payload2);
        }).catch(() => {
          let payload2 = {
            snackBarText: `Something went wrong! \nPlease try again.`,
            snackBarColor: "error",
          };
          this.$store.dispatch("notify/setSnackbarMessage", payload2);
          this.dialog = false;
        });
      }
    }
  },
};
</script>
<style scoped>
.proof-link {
  cursor: pointer;
}

.cityDetailsContainer {
  min-height: 500px;
}
</style>
